<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="updatePassword">
      <div class="row px-2 align-items-center">
        <div class="col-6">
          <h6 class="font-weight-bold text-left">Change Password</h6>
        </div>
        <div class="col-6 text-right">
          <template v-if="toEdit">
            <button class="e-btn e-btn-outlined-red e-btn mr-2" type="button" @click="cancelEdit">Cancel</button>
            <button class="e-btn e-btn-blue e-btn" type="submit">Save</button>
          </template>
          <template v-else>
            <button class="e-btn e-btn-green e-btn" type="button" @click="toEdit=true" >Update Password</button>
          </template>

        </div>
      </div>

      <div class="form-group row text-left pl-2">
        <label for="currentPassword" class="col-sm-2 col-form-label">Current Password</label>
        <div class="col-sm-4 text-left">
          <input type="password" class="form-control form-control-sm" id="currentPassword" :disabled="!toEdit" v-model="fields.current_password" placeholder="Current Password">
          <span class="font-weight-bolder text-danger" v-if="errors.current_password">{{errors.current_password.join(',')}}</span>
        </div>
      </div>
      <div class="form-group row text-left pl-2">
        <label for="newPassword" class="col-sm-2 col-form-label">New Password</label>
        <div class="col-sm-4">
          <input type="password" class="form-control form-control-sm" v-model="fields.new_password"  :disabled="!toEdit" id="newPassword" placeholder="New Password">
          <span class="font-weight-bolder text-danger" v-if="errors.new_password">{{errors.new_password.join(',')}}</span>
        </div>
      </div>
      <div class="form-group row text-left pl-2">
        <label for="newPasswordConf" class="col-sm-2 col-form-label">Confirm Password</label>
        <div class="col-sm-4 ">
          <input type="password" class="form-control form-control-sm" v-model="fields.new_password_confirmation"  :disabled="!toEdit" id="newPasswordConf" placeholder="Confirm Password">
          <span class="font-weight-bolder text-danger" v-if="errors.new_password_confirmation">{{errors.new_password_confirmation.join(',')}}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {UserService} from "@/services/UserService";
import {AlertService} from "@/services/AlertService";

export default {
  name: "ChangePassword",
  data(){
    return {
      fields:{
        current_password:null,
        new_password:null,
        new_password_confirmation:null,
      },
      errors:{},
      toEdit:false,
    }
  },
  methods:{
      async updatePassword() {
        if(await AlertService.confirmUpdateAlert('Do you want to update this users password')){
          this.fields.user_id=this.$route.params.id
          const response = await UserService.updatePassword(this.fields);
          if(response.hasOwnProperty('success') && response.success){
            AlertService.successAlert('Password updated Successfully', 'UPDATE PASSWORD')
            this.resetFields();
          }
          else {
            if(response.response.status === 422){
              this.errors=response.response.data.errors;
            }
            else{
              this.errors=null
            }
          }
        }


      },
      async resetFields(){
        this.fields={
          current_password:null,
            new_password:null,
            new_password_confirmation:null,
        }
        this.toEdit=false;
        this.errors={}
      },
      async cancelEdit(){
        if(await AlertService.cancelAlert()){
           this.resetFields();
        }
      }
  }
}
</script>

<style scoped>

</style>
